export const enhancer = el => {

  const language = document.querySelector('html').getAttribute('lang');
  const formId = el.getAttribute('data-form_id');

  let url = 'https://admin.mokumboot.nl/en/calculator/';
  if (language === 'nl-NL') {
    url = 'https://admin.mokumboot.nl/calculator/';
  }

  if (language === 'fr-FR') {
    url = 'https://admin.mokumboot.nl/fr/calculator/';
  }

  if (language === 'de-DE') {
    url = 'https://admin.mokumboot.nl/de/calculator/';
  }

  if (window.location.hostname === "localhost" || window.location.hostname === "mokumboot.localhost") {
    url = '/calculator/';
  }

  const queryString = window.location.search;

  if (queryString) {
    url += `${queryString}&form_id=${formId}`;
  } else {
    url += `?form_id=${formId}`;
  }

  // create meta
  const meta = document.createElement('meta');
  meta.setAttribute('name', 'viewport');
  meta.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no');

  // create iframe
  const iframe = document.createElement('iframe');
  iframe.setAttribute('src', url);
  iframe.setAttribute('height', '400px');
  iframe.classList.add('calculator--charter');
  el.appendChild(iframe);
};
