const hide = (el) => {
  el.classList.add('is-hidden');
};

export const enhancer = el => {
  if (sessionStorage.getItem('bookNowButton')) {
    hide(el);
  }
  el.querySelector('svg').addEventListener('click', (e) => {
    sessionStorage.setItem('bookNowButton', 'hide');
    hide(el);
  });
};
