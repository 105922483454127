// Functie om de juiste video te laden op basis van schermgrootte
export const loadResponsiveVideo = el => {
  const videoElement = el.querySelector('video');
  const videoSource = el.querySelector('source');

  if (window.matchMedia("(min-width: 1260px)").matches) {
    videoSource.src = videoElement.getAttribute('data-large');
  } else {
    videoSource.src = videoElement.getAttribute('data-small');
  }
  videoElement.muted = true;
  videoElement.load();
  videoElement.play().catch(error => {
    console.log('Autoplay is geblokkeerd door de browser:', error);
  });
};

export const enhancer = el => {
  window.onload = loadResponsiveVideo(el);
  window.addEventListener('resize', loadResponsiveVideo(el));
};
