/* eslint-disable prefer-template */
export const enhancer = el => {
  const figure = el.querySelector('.image-text-block__figure');
  const figureHeight = figure.offsetHeight;
  const body = el.querySelector('.image-text-block__body').clientHeight;
  const bodyHeight = body.offsetHeight;
  if (figureHeight > bodyHeight) {
    el.classList.add('image-text-block--image--center');
  } else {
    el.classList.add('image-text-block--image--top');
  }
};
