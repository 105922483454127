/* eslint-disable max-len */
// prices
const prices = {
  "vmr": "2.5",
  "sleepboot": {
    "2": 535.00,
    "25": 640.00,
    "3": 750.00,
  },
  "sloep": {
    "2": 425.00,
    "25": 530.00,
    "3": 640.00,
  },
  "boarding": {
    "inside": 49.05,
    "outside": 98.10,
  },
  "drinks_a": {
    "2": 15.67,
    "25": 19.36,
    "3": 22.99,
  },
  "drinks_b": {
    "2": 19.30,
    "25": 24.14,
    "3": 28.98,
  },
  "drinks_c": {
    "2": 24.14,
    "25": 30.19,
    "3": 36.24,
  },
  "drinks_d": {
    "2": 19.95,
    "25": 24.95,
    "3": 29.95,
  },
  "nuts": {
    "2": 5,
    "25": 6.25,
    "3": 7.50,
  },
  "bitterballen": 9.81,
  "bitterballen_vegan": 9.81,
  "plukbrood": 9.81,
  "hollandse_klassiekers": 10.36,
  "bittergarnituur": 10.85,
  "bittergarnituur_de_luxe": 15.81,
  "charcuterie": 15.81,
  "vegan": 28.29,
  "tapas": 28.29,
  "burger_a": 17.39,
  "burger_b": 17.39,
  "bbq": 23.93,
  "italiaans": 27.20,
  "indonesisch": 28.29,
  "stamppot": 22.35,
  "buffet_vegan": 28.29,
  "lunch_menu_1": 19.57,
  "lunch_menu_2": 23.44,
};

const foodFields = {
  "bitterballen": {
    "id": "#input_1_27",
    "step": 1,
    "min": 0,
  },
  "bitterballen_vegan": {
    "id": "#input_1_33",
    "step": 1,
    "min": 0,
  },
  "plukbrood": {
    "id": "#input_1_34",
    "step": 1,
    "min": 0,
  },
  "hollandse_klassiekers": {
    "id": "#input_1_35",
    "step": 1,
    "min": 0,
  },
  "bittergarnituur": {
    "id": "#input_1_36",
    "step": 1,
    "min": 0,
  },
  "bittergarnituur_de_luxe": {
    "id": "#input_1_38",
    "step": 1,
    "min": 0,
  },
  "charcuterie": {
    "id": "#input_1_37",
    "step": 1,
    "min": 0,
  },
  "vegan": {
    "id": "#input_1_39",
    "step": 1,
    "min": 0,
  },
  "tapas": {
    "id": "#input_1_40",
    "step": 1,
    "min": 0,
  },
  "burger_a": {
    "id": "#input_1_47",
    "step": 1,
    "min": 0,
  },
  "burger_b": {
    "id": "#input_1_46",
    "step": 1,
    "min": 0,
  },
  "bbq": {
    "id": "#input_1_49",
    "step": 1,
    "min": 0,
  },
  "italiaans": {
    "id": "#input_1_51",
    "step": 1,
    "min": 0,
  },
  "indonesisch": {
    "id": "#input_1_50",
    "step": 1,
    "min": 0,
  },
  "stamppot": {
    "id": "#input_1_52",
    "step": 1,
    "min": 0,
  },
  "buffet_vegan": {
    "id": "#input_1_53",
    "step": 1,
    "min": 0,
  },
  "lunch_menu_1": {
    "id": "#input_1_42",
    "step": 1,
    "min": 0,
  },
  "lunch_menu_2": {
    "id": "#input_1_43",
    "step": 1,
    "min": 0,
  },
};

const totals = {};

const calculatePrice = (el, inputs) => {
  let totalFoodCosts = 0;
  let vat = 0;
  let vatLow = 0;
  let vatHigh = 0;
  let boatTypeLabelId = '';

  const formId = Number(el.getAttribute('data-form_id'));
  const people = el.querySelector(`#input_${formId}_1`).value;
  const duration = el.querySelector(`#input_${formId}_5`).value.replace(/\D/g, '');

  totals.people = people;
  totals.total_persons = `(${people}x)`;
  totals.boat_quantity = '';
  totals.total = 0;

  // boat price
  if (el.querySelector(`#choice_${formId}_4_0`).checked) {
    totals.boat_price = prices.sloep[duration];
    totals.boat_type = totals.boat_price;
    boatTypeLabelId = `#${el.querySelector(`#choice_${formId}_4_0`).id.replace('choice', 'label')}`;
    totals.boat_name = el.querySelector(boatTypeLabelId).innerText;
  }
  if (el.querySelector(`#choice_${formId}_4_1`).checked) {
    totals.boat_price = prices.sleepboot[duration];
    totals.boat_type = totals.boat_price;
    boatTypeLabelId = `#${el.querySelector(`#choice_${formId}_4_1`).id.replace('choice', 'label')}`;
    totals.boat_name = el.querySelector(boatTypeLabelId).innerText;
  }

  if (people > 35) {
    totals.boat_type = totals.boat_price * 2;
    totals.boat_quantity = ' (2x)';
  }
  if (people > 70) {
    totals.boat_type = totals.boat_price * 3;
    totals.boat_quantity = ' (3x)';
  }

  // boarding costs
  if (el.querySelector(`#choice_${formId}_6_2`) && el.querySelector(`#choice_${formId}_6_2`).checked) {
    totals.step_on = prices.boarding.inside;
  } else if (el.querySelector(`#choice_${formId}_6_3`) && el.querySelector(`#choice_${formId}_6_3`).checked) {
    totals.step_on = prices.boarding.outside;
  } else {
    totals.step_on = 0;
  }

  // offboarding costs
  if (el.querySelector(`#choice_${formId}_8_2`) && el.querySelector(`#choice_${formId}_8_2`).checked) {
    totals.step_off = prices.boarding.inside;
  } else if (el.querySelector(`#choice_${formId}_8_3`) && el.querySelector(`#choice_${formId}_8_3`).checked) {
    totals.step_off = prices.boarding.outside;
  } else {
    totals.step_off = 0;
  }

  // same boarding costs
  if (el.querySelector(`#choice_${formId}_9_0`) && el.querySelector(`#choice_${formId}_9_0`).checked) {
    totals.step_off = totals.step_on;
  }

  totals.boarding_costs = totals.step_on + totals.step_off;

  // drinks package
  if (el.querySelector(`#choice_${formId}_11_0`) && el.querySelector(`#choice_${formId}_11_0`).checked) {
    totals.drinking_package = prices.drinks_a[duration] * people;
  }

  if (el.querySelector(`#choice_${formId}_11_1`) && el.querySelector(`#choice_${formId}_11_1`).checked) {
    totals.drinking_package = prices.drinks_b[duration] * people;
  }

  if (el.querySelector(`#choice_${formId}_11_2`) && el.querySelector(`#choice_${formId}_11_2`).checked) {
    totals.drinking_package = prices.drinks_c[duration] * people;
  }

  if (el.querySelector(`#choice_${formId}_11_3`) && el.querySelector(`#choice_${formId}_11_3`).checked) {
    totals.drinking_package = 0;
  }

  // food
  Object.keys(foodFields).forEach(key => {
    if (el.querySelector(foodFields[key].id)) {
      const foodFieldWrapper = foodFields[key].id.replace('input', 'field');
      if (el.querySelector(foodFields[key].id).value > 0) {
        el.querySelector(foodFieldWrapper).classList.add('food-selected');
        totalFoodCosts += el.querySelector(foodFields[key].id).value * parseFloat(prices[key]);
      } else {
        el.querySelector(foodFieldWrapper).classList.remove('food-selected');
      }
    }
  });

  const sections = el.querySelectorAll('.gfield--type-section');
  sections.forEach((section) => {
    let quantityTotal = 0;
    const quantityFields = el.querySelectorAll(`${section.dataset.target} input[type="number"]`);
    quantityFields.forEach((quantityField) => {
      quantityTotal += quantityField.value * 1;
    });
    const sectionTitle = section.querySelector('.gsection_title');
    if (quantityTotal) {
      sectionTitle.innerHTML = `${quantityTotal}<small>x</small> ${sectionTitle.getAttribute('data-title')}`;
    } else {
      sectionTitle.innerHTML = sectionTitle.getAttribute('data-title');
    }
  });

  totals.food = totalFoodCosts;
  totals.vmr = prices.vmr * people;

  const subtotalFields = ['boat_type', 'boarding_costs', 'drinking_package', 'food', 'vmr'];
  subtotalFields.forEach((item) => {
    if (totals[item]) {
      totals.total += totals[item];
    }
  });

  // boat_type
  if (totals.boat_type) {
    vatLow += totals.boat_type;
  }

  // boarding_costs
  if (totals.boarding_costs) {
    vatLow += totals.boarding_costs;
  }

  // food
  if (totals.food) {
    vatLow += totals.food;
  }

  // drinking package
  if (totals.drinking_package) {
    vatHigh += totals.drinking_package;
  }

  // calc low vat 9%
  if (vatLow) {
    vat = (vatLow / 109) * 9;
  }

  // calc hight vat 21%
  if (vatHigh) {
    vat += (vatHigh / 121) * 21;
  }

  totals.subtotal = totals.total - vat;
  totals.vat = vat;
  totals.price_per_person = totals.total / people;

  if (window.location !== window.parent.location) {
    window.parent.postMessage({ "func": "updatePrice", "var": totals }, "*");
  }

  el.querySelector(`#input_${formId}_54`).value = JSON.stringify(totals);
};

export const enhancer = el => {
  setTimeout(() => {
    // remove wrapper
    document.querySelector('.wrapper').classList.add('wrapper--calculator');
    document.querySelector('.wrapper').classList.remove('wrapper');

    if (window.location !== window.parent.location) {
      // create an Observer instance
      const resizeObserver = new ResizeObserver(entries => {
        const height = entries[0].target.clientHeight + 10;
        window.parent.postMessage({ "func": "resizeIframe", "var": height }, "*");
      });

      // start observing a DOM node
      resizeObserver.observe(document.body);

    } else {
      el.style.padding = '25px';
    }

    // set section title
    const sectionTitles = el.querySelectorAll('.gsection_title');
    sectionTitles.forEach((sectionTitle) => {
      sectionTitle.setAttribute('data-title', sectionTitle.innerHTML);
    });

    // set minimal quantity and steps
    Object.keys(foodFields).forEach(key => {
      if (el.querySelector(foodFields[key].id)) {
        el.querySelector(foodFields[key].id).setAttribute('min', foodFields[key].min);
        el.querySelector(foodFields[key].id).setAttribute('step', foodFields[key].step);
      }
    });

    const inputs = document.querySelectorAll("input, select");
    inputs.forEach((input) => {
      // input.setAttribute('placeholder', input.getAttribute('id'));
      input.addEventListener("change", (event) => {
        calculatePrice(el, inputs);
      });
    });
  }, 1000);
};
