/* eslint-disable prefer-template, no-restricted-syntax, prefer-rest-params */
export const enhancer = () => {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }

  const cookieBanner = document.getElementById('cookie-banner');
  const acceptButton = document.getElementById('accept-cookies');
  const declineButton = document.getElementById('decline-cookies');

  // Functie om cookies in te stellen
  const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = `${name}=${value || ""}${expires}; path=/`;
  };

  // Functie om cookies te krijgen
  const getCookie = (name) => {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let c of ca) {
      while (c.charAt(0) === ' ') c = c.substring(1);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length);
    }
    return false;
  };

  // Controleer of de cookie-banner moet worden weergegeven
  if (!getCookie('cookiesAccepted') && !getCookie('cookiesDeclined')) {
    cookieBanner.style.display = 'block';
  }

  if (getCookie('cookiesAccepted')) {
    setCookie('ad_storage', 'granted', 365);
    setCookie('ad_user_data', 'granted', 365);
    setCookie('ad_personalization', 'granted', 365);
    setCookie('analytics_storage', 'granted', 365);
    setCookie('functionality_storage', 'granted', 365);
    setCookie('personalization_storage', 'granted', 365);
    setCookie('security_storage', 'granted', 365);
  }

  // default
  gtag('consent', 'default', {
    ad_storage: getCookie('cookiesAccepted') ? 'granted' : 'denied',
    ad_user_data: getCookie('cookiesAccepted') ? 'granted' : 'denied',
    ad_personalization: getCookie('cookiesAccepted') ? 'granted' : 'denied',
    analytics_storage: 'granted',
    functionality_storage: 'granted',
    personalization_storage: 'granted',
    security_storage: 'granted',
  });

  // Handle accept button click
  acceptButton.addEventListener('click', () => {
    setCookie('cookiesAccepted', 'true', 365);
    setCookie('ad_storage', 'granted', 365);
    setCookie('ad_user_data', 'granted', 365);
    setCookie('ad_personalization', 'granted', 365);
    setCookie('analytics_storage', 'granted', 365);
    setCookie('functionality_storage', 'granted', 365);
    setCookie('personalization_storage', 'granted', 365);
    setCookie('security_storage', 'granted', 365);
    cookieBanner.style.display = 'none';

    // Activeren van Google Tag Manager scripts
    dataLayer.push({ event: 'cookiesAccepted' });
    gtag('consent', 'update', {
      ad_storage: 'granted',
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      analytics_storage: 'granted',
      functionality_storage: 'granted',
      personalization_storage: 'granted',
      security_storage: 'granted',
    });
  });

  // Handle decline button click
  declineButton.addEventListener('click', () => {
    setCookie('cookiesDeclined', 'true', 5);
    setCookie('ad_storage', 'denied', 5);
    setCookie('ad_user_data', 'denied', 5);
    setCookie('ad_personalization', 'denied', 5);
    setCookie('analytics_storage', 'granted', 365);
    setCookie('functionality_storage', 'granted', 365);
    setCookie('personalization_storage', 'granted', 365);
    setCookie('security_storage', 'granted', 365);
    cookieBanner.style.display = 'none';

    // Activeren van Google Tag Manager scripts
    dataLayer.push({ event: 'cookiesDeclined' });
    gtag('consent', 'update', {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'granted',
      functionality_storage: 'granted',
      personalization_storage: 'granted',
      security_storage: 'granted',
    });
  });
};
