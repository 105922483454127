export const enhancer = (el) => {
  const accordionButtons = el.querySelectorAll('.accordion__panel-button');
  console.log(accordionButtons);
  accordionButtons.forEach(button => {
    button.addEventListener('click', () => {
      const expanded = button.getAttribute('aria-expanded') === 'true';
      const content = document.getElementById(button.getAttribute('aria-controls'));
      button.setAttribute('aria-expanded', !expanded);
      content.hidden = expanded;
    });
  });
};
