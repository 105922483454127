import { forEach } from './util';

export const enhancer = el => {
  const blocks = el.querySelectorAll('.products-block__body');
  forEach(blocks, (elm) => {
    if (elm.getAttribute('data-url')) {
      elm.addEventListener('click', (e) => {
        window.location.href = elm.getAttribute('data-url');
      });
    }
  });
};
