// Import libraries and polyfills
import 'classlist-polyfill';
import domready from 'domready';
import rafPolyfill from './polyfills/request-animation-frame';

// Handler and Enhancer utility
import handle from './modules/handle';
import enhance from './modules/enhance';

// Import functions that are executed on DOMready regardless of DOM
import { enhancer as scrollListener } from './modules/scroll-listener';
import { enhancer as responsive } from './modules/responsive';
import { default as disableHoverStylesOnScroll } from './modules/disable-hover-styles-on-scroll';

// Import handlers
import { handler as classToggler } from './modules/class-toggler';
import { handler as gtmEventHandler } from './modules/gtm-event';

// Import enhancers
import { enhancer as accordionPanel } from './modules/accordion-panel';
import { enhancer as bookNow } from './modules/book-now';
import { enhancer as calculator } from './modules/calculator';
import { enhancer as charterCalculator } from './modules/charter-calculator';
import { enhancer as cookie } from './modules/cookie';
// import { enhancer as formEnhancer } from './modules/form-enhancer';
import { enhancer as gtmEventEnhancer } from './modules/gtm-event';
import { enhancer as imageTextLayout } from './modules/image-text-layout';
import { enhancer as productsBlock } from './modules/products-block';
import { enhancer as scrollNav } from './modules/scroll-nav';
import { enhancer as tours } from './modules/tours';
import { enhancer as video } from './modules/video';

// import { enhancer as instagram } from './modules/instagram';
// import { enhancer as newsletterSignup } from './modules/newsletter-signup';

const executeOnReady = () => {
  disableHoverStylesOnScroll(); // Disable hover styles on scroll
  scrollListener(); // Initialise central scroll listener
  responsive(); // Set document width on resize and orientation change

  window.requestAnimationFrame = window.requestAnimationFrame || rafPolyfill;

  if (typeof window.Promise === 'undefined') {
    window.Promise = Promise;
  }
};

const main = () => {
  executeOnReady();
  handle({
    classToggler,
    gtmEventHandler,
  });
  enhance({
    accordionPanel,
    bookNow,
    calculator,
    charterCalculator,
    cookie,
    gtmEventEnhancer,
    imageTextLayout,
    productsBlock,
    scrollNav,
    tours,
    video,
  });
};

domready(main);
